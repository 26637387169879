.compose-container {
	.wrap-compose {
		overflow: hidden;
		border: solid 1px #ddd;
		border-radius: 0 5px 5px 0;
	}
	.mail-header {
		height: 50px;
		border-bottom: 1px solid #D8D8D8;
		padding: 15px;
	 	background: #EFEFEF;
		h4 {
		  	margin-top: 3px;
		  	font-size: 16px;
		  	color: #616161;
		 }
	}
	.receipient {
		height: 45px;
		padding: 11px 15px;
		border-bottom: 1px solid #D8D8D8;
		strong.to {
			opacity: 0.7;
			font-size: 11.5px;
		} 
		
		.label-primary {
			padding-top: 5px;
			padding-left: 12px;
			padding-bottom: 5px;
			padding-right: 12px;
			margin-left: 50px;
			border-radius: 20px;
		}
	}
	.subject {
		height: 37px;
		border-bottom: 1px solid #D8D8D8;
		padding: 8px 15px;
		// margin-bottom: 15px;
		strong.subjetc {
			margin-left: 15px;
			font-size: 12px;
		}
		strong.strong-header {
			opacity: 0.7;
			font-size: 11.5px;
		}
	}
	.send-footer {
		border-top: 1px solid #D8D8D8;
		padding: 15px;
		.btn {
			width: 75px;
		}
		.fa {
			font-size: 19px;
		}
		.fa-trash-o {
			color: @brand-danger;
			margin-top: 7px;
			margin-right: 10px;
		}
	}
}

.cke_1 {
	border-radius: 0 !important;
}