.panel {
	
	.panel-heading {
		
		
		.panel-title {
			font-weight: 300;	
		}
	}
	.panel-body {
		position: relative;
		h4 {
			margin-top: 0;
		}
	}
}
.img-panel {
	.img-thumbnail {
		width: 160px;
		margin-left: 10px;
	}
}
.panel-default {
	.panel-heading {
		background: #cecece;
		color: #FFFFFF;
	}
}
.panel-group {
	.panel-heading {
		padding: 15px;
		.panel-title {
			font-size: 14px;
		}
	}
	.panel {
		border:solid 1px #ddd;
	}
}



blockquote {
	font-size: 14.5px;
	border-left: 4px solid @brand-primary;
}
blockquote.pull-right {
	border-right: 4px solid @brand-info;
}

