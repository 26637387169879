.growl.growl-default {
    color: #FFF;
    background: @brand-primary; }
  .growl.growl-error {
    color: #FFF;
    background: @brand-danger; }
  .growl.growl-notice {
    color: #FFF;
    background: @brand-info; }
  .growl.growl-warning {
    color: #FFF;
    background: @brand-warning; }