html, body {
	min-height: 100%;
	position: relative;
	height: 100%;
	overflow-x: hidden;
	font-family: 'Lato'sans-serif;
}

* {
	ul {
		list-style-type: none;
	}
}


#top-navbar~#body-container {	
	padding-top: @topnav-height;
	background: @container-color;
}
.conter-wrapper {
	min-height: 600px;
	padding: 15px;
}


#body-container {
	position: relative;
	min-height: 100%;
	left: 0;
	padding-bottom: 50px;
	.transition;
}
.codeWrapper {
	background-color: #020111;
	color: #38F527;
}
div#footer-wrap {
	padding: 15px;
	font-size: 12px;
	line-height: 19px;
	background: #e2e6ea;
	color: #999;
	border-top: 1px solid #ced0d2;
	position: absolute;
	left: 0;
	right: 0;
	bottom:0;
	span.pull-right {
		font-size: 18px;
		a {
			color: inherit;
			opacity: 0.4;
			&:hover {
				opacity: 0.9;
				.fa-facebook-square {
					color: #5172F9;
				}
				.fa-twitter-square {
					color: #40CAE4;
				}
			}
		}
	}
}

@media (min-width: 768px) {

	#sidenav~#body-container {
		margin-left: @sidenav-width;
	}

}
@media (max-width: 767px) { 
	#app-container {
		&.push-right {
			#sidenav~#body-container {
				left: @sidenav-width;
			}
			#sidenav { 
				left: 0;
			}
			#footer-wrap {
				left: @sidenav-width;
			}
		}
	}
}

.home-wrapper {
	margin: -15px;
	
}

.photo-container {
	
	a {
		color: #737272;
		font-size: 13px;
	}
	.dash-profile {
	  width: 90px;
	  border-color: #737272;
	  padding: 2px;
	}
}
.circles-text {
  position: absolute;
  top: -8px;
  left: 0px;
  text-align: center;
  width: 100%;
  font-size: 20px;
  height: 110px;
  line-height: 110px;

}
.circles-text1 {
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: center;
  width: 100%;
  font-size: 20px;
  height: 110px;
  line-height: 110px;

}
#circles-1, #circles-2, #circles-3 {
	display: inline-block;
	margin-right: 20px;
}
.mtop {
	margin-top: 1.5%;
}
.inside-text {
  margin-top: -93px;
  font-size: 11px;
}
.circles {
	display: block;
}
.btn-centered {
	text-align: center;
}
.home-back {
	height: 210px;
	margin-bottom: 2%;
	width: 58%;
	float: left;
	display: inline-block;
	overflow: hidden;
	/*background: rgba(110,77,36,1);
	background: -moz-linear-gradient(bottom, rgba(110,77,36,1) 0%, rgba(70,71,64,1) 100%);
	background: -webkit-gradient(left bottom, left top, color-stop(0%, rgba(110,77,36,1)), color-stop(100%, rgba(70,71,64,1)));
	background: -webkit-linear-gradient(bottom, rgba(110,77,36,1) 0%, rgba(70,71,64,1) 100%);
	background: -o-linear-gradient(bottom, rgba(110,77,36,1) 0%, rgba(70,71,64,1) 100%);
	background: -ms-linear-gradient(bottom, rgba(110,77,36,1) 0%, rgba(70,71,64,1) 100%);
	background: linear-gradient(to top, rgba(110,77,36,1) 0%, rgba(70,71,64,1) 100%);*/
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6e4d24', endColorstr='#464740', GradientType=0 );
}
.home-canvas {
	max-height: 224px;
	min-width: 625px;
	margin-left: 0px;
	margin-bottom: -5px;
	margin-top: 10px;
}
hr.homepanel {
	margin-top: 10px;
	margin-bottom: 11px;
}
ul.home-ul {
	list-style-type: none;
	.label {
		float: right;
		border-bottom-left-radius: 30px;
		border-top-left-radius: 30px;
		margin-bottom: 1%;

	}
	li {
		border-bottom: 1px solid #DEDEDE;
		margin-left: -40px;
		a {
			color: inherit;
			opacity: 1;
			display: table;
			height: 55px;
			&:hover {
				opacity: 0.8;
			}
		}
	}
}
.panel-fixed {
	.wrap-home {
		margin-top: 50px;
		
		/*overflow: auto;*/
		border-top: 1px solid #CACACA;
	}
	.home-ul {
		height: 210px;
		overflow-x: auto;

	}
	.happened {
	  display: table-cell;		
	  font-size: 13px;
	  margin-top: -8%;
	  padding-bottom: 2%;
	  vertical-align: middle;
  	  padding-left: 17px;
  	  color: #505050;
	}
	.panel-here {
		width: 50px;
		display: table-cell;
		border-right: 1px solid #D6D6D6;
		vertical-align: middle;
		padding-left: 25px;
		.text-muted {
			font-size: 11px;
			margin-left: 6px;
		}
	}

	.panel-body {
		padding: 0px;	
		
	}
	height: 260px;
	position: relative;
	.messages-list {
		border-bottom: 1px solid #E7E7E7;
		ul {
			list-style: none;
			  padding-left: 0px;
			  height: 210px;
			  overflow: auto;
			  overflow-x: hidden;
			 li {
			 	border-bottom: 1px solid #CECECE;
			 	height: 70px;
			 }
			 .messages-item-time {
				display: table-cell;
				width: 80px;
				vertical-align: middle;
				text-align: center;
				border-right: solid 3px #ccc;
			    padding: 10px;
  				line-height: 14px;
			 } 
			 .mess-content {
			 	display: table-cell;
			 	position: relative;
			 	vertical-align: middle;	
			 	padding-right: 13px;	 	
			 }
			.text1 {
				opacity: 0.8;
				font-size: 10px;
  			}
			.inbox-img {
					height: 37px;
					width: 37px;
					float: left;
				}
			.table-wrapper {
				display: table;
				width: 100%;
				margin-left: 4%;
				.foo {
					display: table-cell;
					vertical-align: middle;
					width: 40px;
				}
				.rest {
					display: table-cell;
					vertical-align: middle;
					padding-left: 2%;
					opacity: 0.85;
					font-size: 11px;
					padding-right: 6px;
					p {
						margin: 0;
					}
 				.name {
 					font-size: 13px;
 					
 				}
				.messages-item-content {
				  
				}
				
			}

			}	
			.fa {
				position: absolute;
				left: -7.5px;
				top: 50%;
				margin-top: -5px;
				&:hover {
					color: inherit;
				}
			}
			.primaryfa {
				color: @brand-primary;
			}
			.infofa {
				color: @brand-info;
			} 
			.successfa {
				color: @brand-success;
			}
			.dangerfa {
				color: @brand-danger;
			}
			.warningfa {
				color: @brand-warning;
			}
			
			}
		a {
				color: inherit;
				opacity: 1;
				display: table;
				width: 100%;
				height: 100%;
				&:hover {
					opacity: 0.8;
				}
			}
	

	}
}
.date-here {
	font-size: 11px;
	margin-left: 7px;
}
.head-fixed {
	position: absolute;
	z-index: 1;
	width: 88%;
	background: white;
	top: 0;
	padding-top: 18px;
	padding-left: 4%;
}
.messages-footer {
	position: absolute;
	z-index: 1;
	padding-left: 35%;
 	padding-top: 14px;
}
.panel-holder {
	margin-bottom: auto;
	ul {
		padding-left: 0;
		li {
			list-style-type: none;
			padding-bottom: 13px;
			border-bottom: 1px solid #DADADA;
			margin-top: 10px;
			.switch-container {
				float: right;
				margin-top: -3px;
			}
		}
	}
	line-height: 20px;
	.checkbox1 {
		  font-size: 13px;
		  padding-top: 8px;
		  font-weight: 100;
		  margin-bottom: -4px;
		  padding-right: 2px;
	}
	.radio1 {
		font-weight: 100;
		font-size: 13px;
		padding-top: 8px;
		margin-bottom: -4px;
	}
}
#cbar1 {
	background: white;
}
.chartheight {
	min-height: 250px;
}
.home-table {
	display: table;
	width: 97.5%;
	margin-left: 15px;
	margin-right: 15px;
	background: #FFFFFF;
	.home-left {
		display: table-cell;
		vertical-align: middle;
		width: 50%;
	}
	.home-right {
		display: table-cell;
		vertical-align: middle;
	}
}
.lighter {
	margin: 0;
	border-top: 1px solid #424242;
}
.btn-block {
	font-size: 20px;
}
@media (max-width: 767px) 
{
	.home-back {
		width: 100%;
		margin-top: -4px;

	}

}

.striped-bg {
	background-image: url("../img/bg-strips-light.png");
	background-repeat: repeat;
}

#app-container { 
	min-height: 100%;
	position: relative;
	height: 100%;
}

.fa-expand-toggle {
	&:before {
		content: "\f066";
	}
}
.box-section {

/*	background: linear-gradient(to bottom left, #33AA9A, #656766 );*/
	
 	background: url(../img/login-bg.jpg);
 	background-size: cover;

	#app-container {
		max-width: 1200px;
		margin: 0 auto;
		#top-navbar {
			max-width: 1200px;
			margin: 0 auto;
		}
	}
	.fa-expand-toggle {
		&:before {
			content: "\f065";
		}
	}
}

.display-default {
	a.navbar-brand {
		margin-top: 0;
		font-size: 19px;
	}
}
.subtitle {
	margin-top: 5px;
	opacity: 0.7;
}

#sidenav {

	.c3-axis-x {
		text {
			fill: darken(#FFF, 40%);
		}
	}

}