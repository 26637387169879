@font-face {
    font-family: 'latothin';
    src: url('../fonts/lato-thin-webfont.eot');
    src: url('../fonts/lato-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-thin-webfont.woff2') format('woff2'),
         url('../fonts/lato-thin-webfont.woff') format('woff'),
         url('../fonts/lato-thin-webfont.ttf') format('truetype'),
         url('../fonts/lato-thin-webfont.svg#latothin') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latoregular';
    src: url('../fonts/lato-regular-webfont.eot');
    src: url('../fonts/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-regular-webfont.woff2') format('woff2'),
         url('../fonts/lato-regular-webfont.woff') format('woff'),
         url('../fonts/lato-regular-webfont.ttf') format('truetype'),
         url('../fonts/lato-regular-webfont.svg#latoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}