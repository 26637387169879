.ball-pulse {
	div {
	background-color: @brand-primary;
	height: 10px;
	width: 10px;
	}
}	

.line-scale {
	div {
		background-color: @brand-primary;
		height: 24px;
		width: 6px;
		border-radius: 20%;
	}
}

.loader-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  z-index: 33;
  text-align: center;
  span.loader-inner.line-scale {
	  position: absolute;
	  top: 50%;
	  margin-top: -12px;
	  left: 0;
	  right: 0;
	}
}