.stat {

	border-radius: 5px;
	overflow: hidden;
	padding: 0 10px;

	.icon-col {
		text-align: center;
		height: 100px;
		padding: 25px 0;
		color: #FFF;
	}

	.text-col {

		position: relative;

		.fa {
			position: absolute;
			right: -20px;
			bottom: -40px;
			color: rgba(0,0,0,0.05);
			font-size: 120px;
		}

		color: lighten(@gray-base, 60%);
		padding-left: 25px;

		h2 {
			font-weight: 300;
		}

	}

}