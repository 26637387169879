.btn-rounded {
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-o-border-radius: 30px;
	border-radius: 30px;

}
.btn-bordered {
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-o-border-radius: 30px;
	border-radius: 30px;
	background: #FFFFFF;
	border-width: 2px;
	&:hover {
		color: #ffffff;
	}
	&.btn-primary {
		border-color: @brand-primary;
		color: @brand-primary;
		&:hover {
			background: @brand-primary;
			color: #ffffff;
		}
	}
	&.btn-info {
		border-color: @brand-info;
		color: @brand-info;
		&:hover {
			background: @brand-info;
			color: #ffffff;
		}
	}
	&.btn-warning {
		border-color: @brand-warning;
		color: @brand-warning;
		&:hover {
			background: @brand-warning;
			color: #ffffff;
		}
	}
	&.btn-success {
		border-color: @brand-success;
		color: @brand-success;
		&:hover {
			background: @brand-success;
			color: #ffffff;
		}
	}
	&.btn-danger {
		border-color: @brand-danger;
		color: @brand-danger;
		&:hover {
			background: @brand-danger;
			color: #ffffff;
		}
	}
	&.disabled {
		color: #717171;
		

	}
}
.text-success {
	color: @brand-success;
}
.text-info {
	color: @brand-info;
}
.text-warning {
	color: @brand-warning;
}
.text-danger {
	color: @brand-danger;
}
.btn-circle {
	border-radius: 50%;
	width: 50px;
}
.btn-primary{
	border-color: @brand-primary;
}
.btn-success {
	border-color: @brand-success;
} 
.btn-info {
	border-color: @brand-info;
} 
.btn-warning {
	border-color: @brand-warning;
} 
.btn-danger {
	border-color: @brand-danger;
}

.alert-primary{
	border-color: @brand-primary;
}
.alert-success {
	border-color: @brand-success;
} 
.alert-info {
	border-color: @brand-info;
} 
.alert-warning {
	border-color: @brand-warning;
} 
.alert-danger {
	border-color: @brand-danger;
}

button.btn.btn-primary.btn-rounded.dropdown-toggle.haha {
	border-left: 1px solid darken(@brand-primary, 5%);
}
button.btn.btn-success.btn-rounded.dropdown-toggle.haha {
	border-left: 1px solid darken(@brand-success, 10%);
}
button.btn.btn-warning.btn-rounded.dropdown-toggle.haha {
	border-left: 1px solid darken(@brand-warning, 10%);
}
button.btn.btn-danger.btn-rounded.dropdown-toggle.haha {
	border-left: 1px solid darken(@brand-danger, 10%);
}
button.btn.btn-primary.btn-bordered.dropdown-toggle.haha {
	border-left: 1px solid @brand-primary;
}
button.btn.btn-success.btn-bordered.dropdown-toggle.haha {
	border-left: 1px solid @brand-success;
}
button.btn.btn-info.btn-bordered.dropdown-toggle.haha {
	border-left: 1px solid @brand-info;
}
button.btn.btn-warning.btn-bordered.dropdown-toggle.haha {
	border-left: 1px solid @brand-warning;
}
button.btn.btn-danger.btn-bordered.dropdown-toggle.haha {
	border-left: 1px solid @brand-danger;
}
button.btn.btn-default.btn-bordered.dropdown-toggle.haha {
	border-left: 1px solid #ccc;
}
button.btn.btn-primary.dropdown-toggle.haha {
	border-left: 1px solid darken(@brand-primary, 5%);
}
button.btn.btn-success.dropdown-toggle.haha {
	border-left: 1px solid darken(@brand-success, 10%);
}
button.btn.btn-warning.dropdown-toggle.haha {
	border-left: 1px solid darken(@brand-warning, 10%);
}
button.btn.btn-danger.dropdown-toggle.haha {
	border-left: 1px solid darken(@brand-danger, 10%);
}