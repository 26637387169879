.panelButton {
	display: inline-block;
	padding: 3px;
	font-size: 12px;
	color: #fff;
	opacity: 0.7;

	&:hover {
		opacity: 1;
		color: #fff;
	}
}


.panel-default {
	.panelButton{
		color:#666;
	}
}

