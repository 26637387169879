table.modal-table {
	tbody {
		tr {
			td {
			border-top: none;
			border-bottom: 1px solid #dddddd;
			}
			td.moleft {
				vertical-align: middle;
				width: 25%;
			}
		}	
	}
}	
.high-padding {
	padding-top: 50px;
	padding-bottom: 50px;
}
.modal-header {
	background: @brand-primary;
	color: #FFF;
	.close {
		color: #FFF;
		opacity: 0.6;
		font-size: 25px;
		&:hover {
			opacity: 0.9;
		}
	}
}
.modal-footer {
	// background: @container-color;
	// padding: 10px;
	.btn {
		font-size: 13px;
		padding-right: 20px;
		padding-left: 20px;
	}
}
.primaryb {
  background-color: @brand-primary;
}
.successb {
  background-color: @brand-success;
}
.infob {
  background-color: @brand-info;
}
.warningb {
  background-color: @brand-warning;
}
.dangerb {
  background-color: @brand-danger;
}
.violetb {
  background-color: #A47EC0;
}
.orangeb {
  background-color: #E188A4;
}
.cyanb {
  background-color: #D5D77C;
}