.select-options {
    width: 300px;
    margin-top: 75px;
}
.docs-content {
    .subject-content {
        ul.features-list {
            list-style-type: disc;
            ul.features-list-extended {
                list-style-type: square;
            }
        }
        .widget-docs {
            .widget-docs-code {
                margin-top: 6px; 
                pre {
                    color: #EEE;
                    background: #444;         
                }
            }
        }
    }
}


.folder-structure-docs {
    margin-top: 70px;
    font-size: 16px;
    ul.features-list {
        list-style-type: disc;
    }
}