.page-title {
  background: #fff;
  border-bottom: solid 1px #ddd;
  padding:15px;
  color: #464646;
  h1 {
    font-size: 23px;
    margin: 0;
    font-weight: normal;
  }
  .breadcrumb {
		background-color: inherit;
		margin-bottom: 0;
		margin-top: 5px;
		a {
			color: inherit;
			&:hover {
				opacity: 0.7;
			}
		}
  }
}