div.carousel-text {
  height: 80px;
  padding: 20px;
  padding-top: 0;
  text-align: center;
  .carousel-control {
  	background: none;
  	display: none;
  }
  .carousel-indicators {
  	bottom: -20px;
  	li {
  		width: 10px;
  		height: 10px;
  		border: 1px solid #CECECE;
  		background: #CECECE;

  	}
  	li.active {
		border: 1px solid @brand-primary;
		background: @brand-primary;
		width: 11px;
		height: 11px;
  	}
  }
}

div.carousel-photo {
  .carousel-indicators {
    display: none;
  }
	.carousel-control {
		background: none;
	}
}
.tool {
	hr {
		margin-top: 12px;
		margin-bottom: 12px;
	}
	.btn-default {
		margin: 3px;
	}
}