.calendar-widget {
	.fc-bg {
		display: none;
	}

	.fc {


		.fc-content-skeleton {
			table {
				border-collapse: separate;
			    border-spacing: 20px 20px;
			}
		}

		.fc-day-grid-container {
			overflow: hidden;
			min-height: 136px;
		}

		color: #999;

		.fc-left {
			color: #EEEEEE;
			h2 {
				font-size: 21px;
				color: @brand-primary;
				font-weight: bold;
			}
		}	

		th, td {
			border: none;
		}

		.fc-basic-view .fc-body .fc-row {
		  height: 24px !important;
		  min-height: 0px;
		}

		th.fc-day-header {
			  font-weight: 100;
		      font-size: 12px;
		}

		.fc-basic-view .fc-body .fc-row {
			min-height: 0em;
		}

		.fc-toolbar {
			margin: 5px;
			.fc-left {
				color: #EEEEEE;
				h2 {
					font-size: 16px;
					text-align: center;
				}
			}	
			.fc-right {
				
				.fc-today-button {
					
					display: none;
				}
				.fc-button-group {
					margin-left: 2px;
					margin-right: 2px;
					.fc-prev-button {
					height: 21px;
					width: 30px;
					background: inherit;
					color: #EEE;
					border: none;
					box-shadow: none;
					}
					.fc-next-button {
					height: 21px;
					width: 30px;
					background: inherit;
					color: #EEE;
					border: none;
					box-shadow: none;
					}	
				}
			}	
		}

		td.fc-today {

			color: #FFF;
			background: @brand-primary;
			border: none;
			border-radius: 2px;

		}
		
	}
}