#sidenav {
	border-radius: 0;
	background-color: @navbar-inverse-bg;
	position: fixed;
	top: 0;
	bottom: 0;
	width: @sidenav-width;
	z-index: 1;
	.transition;

	a#brand-info {
		height: @topnav-height;
		line-height: @topnav-height - 30px;
		padding: 15px;
		display: inline-block;
		background-color: @navbar-inverse-bg;
		width: 100%;
		font-size: 20px;
		color:rgba(255, 255, 255, 0.6);
		font-weight: 300;
		float: none;
		&:hover {
			text-decoration: none;
			color: rgba(255, 255, 255, 0.8);
		}
		img {
			max-width: 90%;
		}
	}


	.sidebar-section-wrap {
		position: absolute;
		left: 0;
		right: 0;
		top: 120px;
		bottom: 0;
		/*overflow: auto;*/
		.section-content {
			
			color: #eee;
		}
	}
	.sidebar-top-nav {
		background-color: @tab;
		border:none;
		position: relative;
		top: @topnav-height;
		li {
			border-radius: 0;
			margin: 0;
			display: table-cell;
			width: 1%;
			padding-top: 3px;
			a {
				border-radius: 0;
				margin: 0;
				border:none;
				background-color: @tab;
				color: rgba(255,255,255,0.8);
				border-right:solid 1px rgba(0,0,0,0.1);
				.transition;
			}
			&.active, &:hover {
				a {
					border-radius: 0;
					background-color: darken(@tab, 6.5%);
					background-image: url("/img/bg-strips-light.png");
					background-repeat: repeat;
					color: rgba(255, 255, 255, 1);
					
				}
			}
		}
	}
	.sidebar-nav {
		font-size: 14px;
		li {			
			a {
				color: rgba(255,255,255,0.6);
				border-bottom: solid 1px rgba(0, 0, 0, 0.1);
				.transition;
				&:hover, &:active, &:focus {
					background: rgba(0, 0, 0, 0.2);
					position: relative;
					color: rgba(255,255,255,0.8);
				}
				>i {
					width: 20px;
				}
			}
			&.active {
				a {
					background: rgba(0, 0, 0, 0.2);
					&:after {
						top: 11px;
						right: 0;
						content: '';
						position: absolute;
						display: inline-block;
						border-top: 12px solid rgba(0, 0, 0, 0);
						border-bottom: 12px solid rgba(0, 0, 0, 0);
						border-right: 10px solid #E9F0F5;
					}
				}
				&:after {
					content: '';
					top: 0;
					bottom: 0;
					background: @brand-primary;
					width: 4px;
					position: absolute;
					left: 0;
				}
			}
			&.sidenav-dropdown {
				max-height: 41px;
				overflow: hidden;
				.transition-slow;

				&.show-subnav {
					max-height: 1000px;
					background-color: rgba(0,0,0,0.2);
				}
				i {
					.transition-slow;
					margin-top: 2px;

				}
				.sidenav-sub-menu {
					li {
						font-size: 13px;
						a {
							padding-left: 20px;
							margin-left: 20px;
						}
						&:after {
							content: '';
							border-right: solid 1px rgba(0,0,0,0.1);
							position: absolute;
							left: 20px;
							top: 0;
							bottom: 0;
						}
					}
				}
			}
		}
	}
	.section-heading {
		color: rgba(255, 255, 255, 0.5);
		padding: 15px;
		border-bottom: solid 1px rgba(0, 0, 0, 0.1);
		text-transform: uppercase;
		font-size: 11px;
		font-weight: 600;
		letter-spacing: 1px;
		line-height: 13px;
	}
}
.photo-container {
	margin-top: 5px;
}
.solution {
  float: left;
  color: @brand-primary;
  font-size: 12px;
  margin-top: 25px;
}
div#calendar {
	.section-heading {
	border-bottom: 1px solid #424242 !important;
	}
	ul.today-ul {
		padding-left: 7px;
		padding-right: 7px;
		margin-bottom: 30px;
		li {
			a {
					color: #737272;
					&:hover {
						color: rgba(255,255,255,.6);
					}
				}
			border-bottom: 1px solid #424242;
			display: table;
			width: 100%;
			.panel-here {
				display: table-cell;
				vertical-align: middle;
				padding-left: 1px;
				padding-top: 12px;
				padding-bottom: 12px;
				border-right: 1px solid #424242;
				.label {
					border-bottom-left-radius: 30px;
					border-top-left-radius: 30px;
					text-shadow: 1px 1px rgba(0,0,0,0.2);
				}
			}
			.happened {
				display: table-cell;
				vertical-align: middle;
				padding: 10px;
				font-size: 13px;
				a {
					color: rgba(255,255,255,0.8);
					&:hover {
						color: rgba(255,255,255,1);
					}
				}
			}
		}
	}
	.dead {
		.label-primary {
			background: #676767;
		}
	}
}
div#notification {
	margin-left: 0;
	margin-right: 0;
	.section-heading {
	border-bottom: 1px solid #424242 !important;
	}
	ul.notif-ul {
		padding-left: 7px;
		padding-right: 7px;
		list-style-type: none;
		li {
			border-bottom: 1px solid #424242;
			font-size: 13px;
			.notification-info {
				color: #737272;
				font-size: 11px;
				line-height: 15px;
				h5 {
					font-size: 12px;
					font-weight: 600;
				}
			}
			.notification-wrap {
				&:hover {
				color: rgba(255,255,255,1);
				}
				padding-left: 3px 5px;
				.fa-stack {
					font-size: 13px;
				}
				.fa-user {
					font-size: 11px;
				}
			}
		}	
	}
}

div#comments {
	.section-heading {
	border-bottom: 1px solid #424242 !important;
	}
	ul.online-members {
		list-style-type: none;
		padding-left: 7px;
		padding-right: 7px;
		.truncate;
		li {
			.chat-image {
				width: 24px;
				margin-right: 10px;
				border: 1px solid #999;
				}
			padding: 10px 15px;
			color: #737272;
			border-bottom: 1px solid #424242;
			font-size: 13px;
			a {
				color: inherit;
				&:hover {
					color: rgba(255,255,255,0.6);
				}
			}
			.fa {
				margin-top: 8px;
			}
		}
	}
}
div#charts {
	h4 {
		font-size: 13px;
		opacity: 0.7;
		color: #737272;
	}
	.c3-chart-arc text {
		display: none;
	}
	.c3-legend-item {
		display: none;
	} 
	.chart-container2 {
		.c3 path, .c3 line {
			stroke: none;
		}


	}
	.transaction {
		padding: 12px;
		h4.eft {
		  margin-right: 105px;
		  margin-bottom: 20px;
		}
		.progress {
			background: lighten(@tab, 10%);
			height: 15px;
			.progress-bar {
				font-size: 10px;
				line-height: 17px;
			}
		}
	}
}



.navbar-brand {	
	.fa {
		color: @brand-primary;
	}
}

@media (min-width: 767px) {

	.hidd {
		display: none;
		
	}
}
@media (max-width: 767px) {
	#sidenav {
		left: -@sidenav-width;
	}
}	


