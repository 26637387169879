.invoice {
	.invoice-logo {
		padding: 12px;
		display: table;
		width: 100%;
		.dashy-left {
			display: table-cell;
			vertical-align: middle;
		}
		.dashy-right {
			display: table-cell;
			vertical-align: middle;
		}
		p {
		  text-align: right;
		  font-size: 15px;
		 
		}
	}
	.btn {
		margin-right: 5px;
	}
	.list-unstyled.invoice-details {
		li {
			line-height: 30px;
		}
	}
}
.wellb {
	background: #FFFFFF;
}

.crisp {
	color: @brand-primary;
	margin-left: 15px;
	margin-top: 15px;

}
.text {
	font-size: 30px;
}

@media (max-width: 992px) {
	.payment-detals-wrap {
		float: none !important;
	}
}